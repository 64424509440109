async function getCheckout(product, email, monthly) {
    const product_id = monthly ? product + "-monthly" : product + "-yearly";

    const url = `https://api.vinkvfx.com/v1/stripe/get_checkout?product=${encodeURIComponent(product_id)}&email=${encodeURIComponent(email)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.text();
        return data;
    } catch (error) {
        console.error('Error fetching checkout URL:', error);
        throw error;
    }
}

export default getCheckout;