import * as React from 'react';
import { useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';
import getCheckout from './Checkout';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 250,
  rounded: 10,
  borderRadius: 1,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

const EmailCollection = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleContinue = () => {
    setIsButtonDisabled(true);
    getCheckout("magicdefocus2-trial", email, false)
      .then(url => {
        const stripped_url = url.replace(/"/g, '');
        window.location.href = stripped_url;
      })
      .catch(error => {
        console.error('Error:', error);
        setIsButtonDisabled(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isButtonDisabled) {
      handleContinue();
    }
  };

  return (
    <div>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={handleOpen}
      >
        Try it out
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-title">Enter your email to continue</h2>
          <TextField
            label="Email"
            variant="standard"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
            slotProps={{
              inputLabel: {
                style: { marginTop: '-6.5px' },
              },
            }}
          />

          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleContinue}
            disabled={isButtonDisabled}
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
        </Box>
      </Modal>
    </div>
  );
};


export default function Trial() {
  return (
    <Container
      id="trial"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '100%', md: '60%' },
          textAlign: { xs: 'center', sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Experience the difference. Try it yourself.
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          MagicDefocus2 offers a 7 day no-strings-attached trial to experience it yourself.
          <br /><br />
        </Typography>

        <EmailCollection />

      </Box>
    </Container>

  );
}
