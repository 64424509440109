import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MagicDefocusIcon() {
  return (
    <SvgIcon sx={{ height: 25, width: 25, mr: 2 }}>
      <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 111.46 118.8">
        <defs>
          <linearGradient id="MagicDefocus2Gradient" data-name="New Gradient Swatch 1" x1="0" y1="59.4" x2="111.46" y2="59.4" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#7d00ff" />
            <stop offset="1" stopColor="#b680ff" />
          </linearGradient>
        </defs>
        <g id="Icon">
          <g id="IconColor">
            <g id="Logo_copy_5">
              <path class="cls-1" d="M93.77,84.49l-10.14-49.02c-.27-1.27-1-2.38-2.07-3.11L35.61.87c-1.17-.8-2.63-1.07-4.01-.73-1.37.34-2.55,1.25-3.21,2.5L9.15,38.69c-1.89,3.56-.12,5.74.47,6.33.58.58,2.77,2.34,6.31.43l16.56-8.94,3.96,8.11-19.54,39.6c-6.85,2.17-16.91,6.67-16.91,14.31,0,3.28,1.98,9.51,15.25,14.41,10.39,3.83,25.33,5.86,43.2,5.86,49.18,0,53.01-15.2,53.01-19.86,0-3.8-3.13-9.33-17.69-14.45ZM44.28,46.84c.68-1.38.68-3.03,0-4.41l-5.98-12.25c-.6-1.22-1.67-2.15-2.96-2.57-.49-.15-1.01-.23-1.52-.23-.82,0-1.64.2-2.37.59l-10.55,5.7,12.96-24.3,42.18,28.91,7.67,37.07c-3.79,1.9-12.34,5.3-25.09,5.3s-23.5-3.96-28.05-6.03l13.71-27.78ZM58.45,110.8c-38.37,0-50.45-9.56-50.45-12.27,0-.99,3.59-4.42,12.45-7.03,1.33-.39,2.45-1.33,3.06-2.58l3.51-7.11c5.18,2.4,16.75,6.84,31.6,6.84,12.46,0,21.54-2.94,26.75-5.26l.91,4.39c.36,1.76,1.64,3.19,3.32,3.74,8.27,2.73,13.31,6.08,13.85,7.51-.15,3.15-8.61,11.77-45,11.77Z" fill="url(#MagicDefocus2Gradient)" />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
