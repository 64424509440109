import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MuiChip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import { InvertColors, LineAxis, MeetingRoom, Visibility, ZoomOutMapOutlined } from '@mui/icons-material';

const items = [
  {
    icon: <Visibility />,
    title: 'Catseye',
    description:
      'Catseye is a non uniform lens artifact that creates "cat-eyed" bokeh shapes at the edge of your image. Besides catseye, it is also called swirly bokeh, mechanical vignetting and optical vignetting.',
    image: "assets/catseye.png",
  },
  {
    icon: <MeetingRoom />,
    title: 'Barndoors',
    description:
      'Barndoors, or called matte box, are physically attached doors to the lens, which allow the camera operator to block incoming light.',
    image: "assets/barndoors.png",

  },
  {
    icon: <ZoomOutMapOutlined />,
    title: 'Astigmatism',
    description:
      'Astigmatism is an artifact that creates stretched shapes gradually towards the edges of the image. ',
    image: "assets/astigmatism.png",

  },
  {
    icon: <LineAxis />,
    title: 'Axial aberration',
    description:
      'Axial aberration, or also called longitudinal aberration is an artifact that occurs depending on the focus distance. It inverts the chromatic aberration depending on if it is in the near, or far field.',
    image: "assets/axial_aberration.png",

  },
  {
    icon: <InvertColors />,
    title: 'Inverse bokeh in foreground',
    description:
      'Inverse the bokeh shape in the foreground. This is caused by the way light rays work physically.',
    image: "assets/inverse_bokehs.png",

  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: 'flex', sm: 'none' },
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, overflow: 'auto' }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        <Box
          sx={{
            mb: 2,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: 280,
            backgroundImage: items[selectedItemIndex] ? `url(${items[selectedItemIndex].image})` : 'none',
          }}
        />

        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: 'text.primary', fontWeight: 'medium' }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

MobileLayout.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.element,
    imageDark: PropTypes.string.isRequired,
    imageLight: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
};

export { MobileLayout };

export default function NonUniform() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="non-uniform" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box sx={{ width: { sm: '100%', md: '100%' } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Even more optical artifact replications
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
        >
          MagicDefocus2 goes beyond basic defocus, enabling you to recreate the subtle nuances of real-world lenses. Non-uniform bokeh allows for the replication of unique artifacts, adding a level of realism that was previously impossible to create.
          <br /><br />
          <b>Best of all, these advanced features now come with near-zero performance impact, so you can achieve stunning results without sacrificing speed.</b>
          <br />
          <span style={{ fontStyle: 'italic', color: 'text.secondary' }}>
            * Except axial-aberration, which requires additional sampling to be done for all channels.
          </span>
        </Typography>

      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row-reverse' },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
              height: '100%',
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: '100%',
                    width: '100%',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: 'action.selected',
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      gap: 1,
                      textAlign: 'left',
                      textTransform: 'none',
                      color: 'text.secondary',
                    },
                    selectedItemIndex === index && {
                      color: 'text.primary',
                    },
                  ]}
                >
                  {icon}

                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </div>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: { xs: '100%', md: '70%' },
            height: 'var(--items-image-height)',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 600,
                height: 600,
                backgroundSize: 'cover',
                backgroundImage: items[selectedItemIndex] ? `url(${items[selectedItemIndex].image})` : 'none',
              }}
            />

          </Card>
        </Box>
      </Box>
    </Container>
  );
}
