import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MagicDefocusIcon from './MagicDefocusIcon';
import Link from '@mui/material/Link';
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const handleSubscription = () => {
    window.open('https://subscription.vinkvfx.com/', '_blank');
  };
  const handleDocumentation = () => {
    window.open('https://docs.magicdefocus.com/', '_blank');
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 2 }}>

            <a href="#hero" style={{ display: 'inline-flex', alignItems: 'center', verticalAlign: 'middle', textDecoration: 'none' }}>
              <MagicDefocusIcon style={{ margin: 0, padding: 0 }} />
            </a>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button variant="text" color="info" size="small" href="#intro">
                Features
              </Button>
              <Button variant="text" color="info" size="small" href="#pricing">
                Pricing
              </Button>
              <Button variant="text" color="info" size="small" href="#faq">
                FAQ
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Button color="primary" variant="text" size="small" onClick={handleSubscription}>
              Manage subscription
            </Button>
            <Button color="primary" variant="outlined" size="small" onClick={handleDocumentation}>
              Documentation
            </Button>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Link href="#intro" style={{ textDecoration: 'none' }}>
                  <MenuItem>
                    Features
                  </MenuItem>
                </Link>
                <Link href="#pricing" style={{ textDecoration: 'none' }}>
                  <MenuItem>
                    Pricing
                  </MenuItem>
                </Link>
                <Link href="#faq" style={{ textDecoration: 'none' }}>
                  <MenuItem>
                    FAQ
                  </MenuItem>
                </Link>
                <MenuItem>
                  <Button color="primary" variant="contained" onClick={handleSubscription} fullWidth>
                    Manage subscription
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button color="primary" variant="outlined" onClick={handleDocumentation} fullWidth>
                    Documentation
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container >
    </AppBar >
  );
}
