import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function Intro() {
  return (
    <Container
      id="intro"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          The next generation
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          MagicDefocus2 is the successor to the original MagicDefocus node you know and love. It’s built on years of feedback and wishes, and is now available as a native plugin for The Foundry Nuke™, <br></br>built in C++ and Rust using Vulkan™ and Metal™.
          <br></br><br></br>
          The feedback was loud and clear: "I want the same, but fast <i>GPU accelerated</i>, <i>physically based</i> and <i>even more features</i>". Well, MagicDefocus2 offers all you ever wished for.
        </Typography>
      </Box>

    </Container>
  );
}
