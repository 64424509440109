import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import { RocketLaunch, Videocam, Layers, AutoAwesome, BlurCircular } from '@mui/icons-material';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Built from the ground up',
    description:
      'MagicDefocus2 is built upon years of artist feedback and wishes. And will continue to do so.',
  },
  {
    icon: <RocketLaunch />,
    title: 'GPU accelerated',
    description:
      'MagicDefocus2 uses GPU acceleration to achieve fast renders and a non-interruptive compositing workflow.',
  },
  {
    icon: <Videocam />,
    title: 'Click and play',
    description:
      'Use your real world camera data to get matching defocus in the blink of an eye.',
  },
  {
    icon: <AutoAwesome />,
    title: 'Extensive non uniform effects',
    description:
      'Now more effects than ever before, replicate the most fine details of any real world artifact for even better photorealism.',
  },
  {
    icon: <Layers />,
    title: 'Deep support',
    description:
      'Handle transparency and difficult edges with ease by utilizing deep image data.',
  },
  {
    icon: <BlurCircular />,
    title: 'Easy to use bokeh creator',
    description:
      'Match and create any bokeh you want to create with the built in bokeh creator.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Summary
          </Typography>
          <Typography variant="body1" >
            A brief overview of the key innovations of MagicDefocus2.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
