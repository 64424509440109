import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How do I contact support?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              You can reach support by creating a ticket at <Link href="https://support.vinkvfx.com" target="_blank" rel="noopener noreferrer">
                support.vinkvfx.com </Link>.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Is Nuke Indie supported?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              At the moment not unfortunately, but work is in progress to make this happen. <Link href="https://newsletter.magicdefocus.com" target="_blank" rel="noopener noreferrer">Subscribe</Link> to the mailing list to get notified when this happens!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What versions of Nuke are supported and which platforms?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              MagicDefocus2 is available for all Nuke (non-indie) versions above and including 13.0. It also supports all operating systems Nuke is available on. This includes macOS (ARM and x86), Windows and Linux.
              <br></br><br></br>
              Whenever a new Nuke version becomes available, it is aimed to publish an updated release as fast as possible to make sure MagicDefocus2 is available on all versions.
              <br></br><br></br>
              On Linux RHEL and Debian based systems are supported. Arch is not yet supported.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What are the system recommendations?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              At least the same requirements as <Link href="https://www.foundry.com/products/nuke-family/requirements" target="_blank" rel="noopener noreferrer">the Foundry Nuke</Link>.

              It is recommended to use the trial first to make sure your system is compatible.

              <br></br>
              <br></br>
              Minimum:
              <Typography component="h3" variant="subtitle2">
                2 cores <br></br>
                8GB RAM <br></br>
                1GB of storage<b></b>
                Nvidia GTX 1050 or higher/RX 570 or higher <br></br>
              </Typography>

              <br></br>
              Recommended:
              <Typography component="h3" variant="subtitle2">
                8 cores or more <br></br>
                16GB RAM or more<br></br>
                1GB of storage<b></b>
                Nvidia RTX 2070 or higher/AMD Radeon 5700 X or higher <br></br>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Is CPU rendering supported?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Yes, MagicDefocus2 does support CPU rendering. However: it is recommended to use a GPU whenever possible, as this is significantly faster. The CPU is only available as a fallback option, so it will prefer the GPU automatically whenever possible.<br></br><br></br>
              But with the rise of machine learning, GPU's are more and more common in most machines.
              In most cases even onboard graphic cards will outperform the CPU easily.<br></br><br></br>

              On macOS only GPU rendering is supported as this is the way Metal works. However, every Mac system has a GPU onboard, so this is not an issue.

              On non-gpu systems a simulated GPU will be used that runs on the CPU, like LLVMpipe.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6d-content"
            id="panel6d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Do you offer an educational discount?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Yes. Please send an email to <Link href="mailto:support@vinkvfx.com" target="_blank" rel="noopener noreferrer"> support@vinkvfx.com
              </Link> and mention your educational details.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7d-content"
            id="panel7d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Do you offer a perpetual license?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              There is no perpetual license available. Instead, a subscription model has been chosen to ensure continued development and support, which includes the delivery of updates during the subscription period.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8d-content"
            id="panel8d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Is offline usage supported?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Yes. Once the license is installed it will work for the entire duration of your subscription. However, you will need to re-activate when your subscription renews. For this reason, an annual license is recommended to keep maintenance to a minimum.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
