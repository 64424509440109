import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';

export default function ClickAndPlay() {

  return (
    <Container
      id="click-and-play"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
      }}
    >
      <Grid container spacing={2} alignItems="center" direction={{ xs: 'column', md: 'row' }}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography
              component="h2"
              variant="h4"
              gutterBottom
              sx={{ color: 'text.primary' }}
            >
              Click and Play
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              MagicDefocus2 ships with a native integration for camera data. Use your real world data to get matching defocus in the blink of an eye. It can be that easy.<br /><br />
              This feature alone has already proven to be a game-changer for show production.
            </Typography>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>

          <video loop muted autoPlay playsInline preload="auto" style={{ width: "100%", height: "100%", borderRadius: "8px", padding: "16px" }}>
            <source src="./assets/camera.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>

        </Grid>
      </Grid>
    </Container>
  );
}
