import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';

export default function ExtensiveDocumentation() {

  return (
    <Container
      id="extensive-documentation"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
      }}
    >
      <Grid container spacing={2} alignItems="center" direction={{ xs: 'column', md: 'row' }}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography
              component="h2"
              variant="h4"
              gutterBottom
              sx={{ color: 'text.primary' }}
            >
              Extensive documentation
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              Daunted to try it out? Don’t worry! MagicDefocus2’s interface is kept as similar as the original node to make it incredibly easy to transition your existing knowledge.
              <br></br><br></br>
              On top of that, every feature is documentated clearly in the documentation that is shipped with the plugin.
            </Typography>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: 1,
            }}
            src="./assets/documentation.png"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
