import * as React from 'react';
import { useState } from 'react';
import { Button, Modal, Box, TextField, Switch, FormControlLabel, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import getCheckout from './Checkout'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';


const CustomSwitch = ({ isMonthly, onChange }) => {
  const theme = useTheme();

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <Box display="flex" alignItems="center">
      <Chip label="Get 2 months free!" variant="outlined" style={{ marginRight: '10px' }} color="primary" />
      <span style={{ marginRight: '-10px', color: isMonthly ? 'grey' : (theme.applyStyles("light") ? 'white' : 'black') }}>
        Annually
      </span>
      <Box display="flex" alignItems="center" justifyContent="center" width="60px">
        <FormControlLabel
          control={
            <Switch
              checked={isMonthly}
              onChange={handleChange}
              size="medium"
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: theme.palette.primary.main,
                },
                '& .MuiSwitch-track': {
                  backgroundColor: theme.palette.primary.main
                },
                '&.Mui-checked .MuiSwitch-thumb': {
                  backgroundColor: theme.palette.primary.main,
                },
                '&.Mui-checked .MuiSwitch-track': {
                  backgroundColor: theme.palette.primary.main,
                },
                '&.MuiSwitch-root': {
                  color: theme.palette.primary.main,
                },
              }}
            />
          }
          label=""
          labelPlacement="start"
        />
      </Box>

      <span
        style={{
          marginLeft: '18px',
          color: isMonthly ? (theme.applyStyles("light") ? 'white' : 'black') : 'grey',
        }}
      >
        Monthly
      </span>
    </Box>
  );
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 250,
  rounded: 10,
  borderRadius: 1,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

const EmailCollection = ({ tier, isMonthly }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleContinue = () => {
    setIsButtonDisabled(true);
    getCheckout(tier.url, email, isMonthly)
      .then(url => {
        const stripped_url = url.replace(/"/g, '');
        window.location.href = stripped_url;
      })
      .catch(error => {
        console.error('Error:', error);
        setIsButtonDisabled(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isButtonDisabled) {
      handleContinue();
    }
  };

  return (
    <div>
      <Button
        fullWidth
        variant={tier.buttonVariant}
        color={tier.buttonColor}
        onClick={handleOpen}
      >
        {tier.buttonText}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-title">Enter your email to continue</h2>
          <TextField
            label="Email"
            variant="standard"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
            slotProps={{
              inputLabel: {
                style: { marginTop: '-6.5px' },
              },
            }}
          />

          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleContinue}
            disabled={isButtonDisabled}
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
        </Box>
      </Modal>
    </div>
  );
};


const tiers = [
  {
    title: 'Node-locked',
    annual_price: '12,50',
    monthly_price: '15',
    description: [
      '1 node locked license',
      'GPU accelerated defocus',
      'Receive updates and new features',
      'Extensive documentation',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
    url: 'magicdefocus2-nodelocked'
  },
  {
    title: 'Floating',
    annual_price: '20,83',
    monthly_price: '25',
    description: [
      '1 floating license',
      'GPU accelerated defocus',
      'Receive updates and new features',
      'Extensive documentation',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
    url: 'magicdefocus2-floating'
  },
  {
    title: 'Site-license',
    annual_price: '250',
    monthly_price: '300',
    description: [
      '50 floating licenses',
      'GPU accelerated defocus',
      'Receive updates and new features',
      'Extensive documentation',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
    url: 'magicdefocus2-site'
  },
];

export default function Pricing() {
  const [isMonthly, setIsMonthly] = useState(false);

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          MagicDefocus2 offers several options. If you are a freelancer/individual the node-locked option is recommended. While for studio's the floating options are the best. Not sure what you need? You can always reach out to support.
        </Typography>
      </Box>
      <CustomSwitch isMonthly={isMonthly} onChange={setIsMonthly} />
      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: 4, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                },
              ]}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography component="h3" variant="h2">
                    €{isMonthly ? tier.monthly_price : tier.annual_price}
                  </Typography>
                  &nbsp;
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      textAlign: 'left', marginTop: '4px',
                      marginLeft: '8px', lineHeight: '1.4'
                    }}
                  >
                    per month<br></br>ex VAT
                  </Typography>
                </Box>

                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{ width: 20, color: 'primary.light' }}
                    />
                    <Typography variant="subtitle2" component={'span'}>
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <div style={{ width: '100%' }}>
                  <EmailCollection tier={tier} isMonthly={isMonthly} />
                </div>

              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          mt: 4,
          p: 2,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          textAlign: 'center',
          backgroundColor: 'background.paper',
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Looking for a company-wide license? Please reach out for custom pricing.
        </Typography>
        <Button variant="outlined" color="primary">
          Reach out
        </Button>
      </Box>
    </Container>
  );
}
