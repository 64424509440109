import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Intro from './components/Intro';
import ClickAndPlay from './components/ClickAndPlay';
import Highlights from './components/Highlights';
import FastPerformance from './components/FastPerformance'
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getMPTheme from './theme/getMPTheme';
import NonUniform from './components/NonUniform';
import ExtensiveDocumentation from './components/ExtensiveDocumentation';
import Trial from './components/Trial';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


const InfoBanner = ({ open, setOpen, message, success }) => (
  <Box
    sx={{
      width: '100%',
      position: 'fixed',
      top: 0,
      zIndex: 9999,
      padding: 8,
      pointerEvents: 'none',
    }}
  >
    <Collapse in={open}>
      <Alert
        severity={success ? "success" : "error"}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
            sx={{ pointerEvents: 'auto' }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2, pointerEvents: 'auto' }}
      >
        {message}
      </Alert>
    </Collapse>
  </Box>
);

export default function MainPage() {
  const [mode, setMode] = React.useState('light');
  const [open, setOpen] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const [seed, setSeed] = useState(1);
  const location = useLocation();
  const update = () => {
    setSeed(Math.random()); // To be honest, I don't know JavaScript and I don't care. I don't like making websites and I just want it to be done. Sorry for the messy code
  }
  React.useEffect(() => {
    const systemPrefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    ).matches;
    setMode(systemPrefersDark ? 'dark' : 'light');
    CookieConsent.run({

      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        functional: {}
      },

      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'Your privacy',
              description: 'Our website uses functional cookies to enhance your experience. Specifically, we use cookies related to the Vimeo iframe to provide you with video content. These cookies help us remember your preferences and improve the functionality of our site.',
              acceptAllBtn: 'Accept',
              acceptNecessaryBtn: 'Reject',
            },
          }
        }
      },
      onConsent: ({ cookie }) => {
        update();
      }
    });
  }, []);


  return (
    <ThemeProvider theme={MPTheme}>
      <CssBaseline enableColorScheme />
      {location.pathname === '/success' && <InfoBanner open={open} setOpen={setOpen} success={true} message={"Thank so much for subscribing. Your subscription was received successfully. Please keep an eye on your mail inbox for a message with your license key. If it takes longer than 30 minutes, please do not hesitate to send an email to support@vinkvfx.com."} />} { }
      {location.pathname === '/invalid-region' && <InfoBanner open={open} setOpen={setOpen} success={false} message={"You are within either the USA or Canada, which is unfortunately not yet supported. Work is currently being done to ensure you are able to use MagicDefocus2 as well. Please subscribe to the newsletter to be updated when that happens."} />} { }

      <Hero key={seed} />
      <AppAppBar />
      <div>
        <Divider />
        <Intro />
        <Divider />
        <ClickAndPlay />
        <Divider />
        <FastPerformance />
        <Divider />
        <NonUniform />
        <Divider />
        <ExtensiveDocumentation />
        <Divider />
        <Highlights />
        <Divider />
        <Trial />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </div>
    </ThemeProvider>
  );
}
